import { Fade } from "react-reveal";
import { ButtonMe } from "../buttons/mainbutton";
import { Para } from "../para";
import { Title } from "../title";
import Qualityin from "./qualityin";

function SerInfo(props){
    return(
        <div className="flex serinfo wid85 column secme">
            <Fade>
                <div className="sernameimg">
                    <img src={`./assets/img/`+props.serimg} className="wid100"/>
                    <div className="sername flex column align-center">
                        <h2 className="ubuntu">{props.name}</h2>
                        <ButtonMe link="#booknow" hashlink={true} buttext="Book Now" classes="blue noshadow"/>
                    </div>
                </div>
            </Fade>
            <div className="quality flex justify-center">
                {                    
                    (props.quality).map(element=>{
                        return <Qualityin parastring={element.quality} img={element.image}/>                
                    })
                }                
            </div>
            <Para parastring={props.infopara}/>
        </div>
    )
}

export default SerInfo;