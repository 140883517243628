import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faFacebookSquare, faYoutube, faInstagram, faInstagramSquare, faLinkedinIn, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';
import { HashLink } from 'react-router-hash-link';
import { Para } from "./para";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";

export default function Footer(){
    return(
        <div className="footer secme wid100 bluebg">
            <div className="footin flex justify-between mob-column mob-align-center mob90">
                <div className="sitemap flex column-wrap mob-row-wrap">
                    <ul className="mob50">
                        <span>About</span>                    
                        <li><HashLink to="./about#us">About Kinships</HashLink ></li>
                        <li><HashLink to="./about#story">Our Story</HashLink ></li>
                        <li><HashLink to="./about#team">Meet Our Team</HashLink ></li>
                    </ul>
                    <ul className="mob50">
                        <span>Services</span>
                        <li><Link to="./services/in-hospital-support">In Hospital Support Services</Link></li>
                        <li><Link to="./services/mobility">Assisted Mobility Services</Link></li>
                        <li><Link to="./services/home-care">Home Care Services</Link></li>
                    </ul>
                    <ul className="mob50">
                        <span>Quick links</span>
                        {/* <li>Shop</li> */}
                        <li><a href="https://blog.kinships.co" target="_blank">Blog</a></li>
                        {/* <li>Careers</li> */}
                        <li><Link to="./contact">Contact</Link></li>                        
                    </ul>
                </div>
                <div className="socials flex column align-center">
                    <img src="./assets/img/logo-white.png" className="footlogo" width="250px"/>
                    <div className="flex align-center">
                        <Para parastring="Follow us on"/>
                        <a href="https://www.facebook.com/myseniorsofficial/" target="_blank" noreferrer noopener><FontAwesomeIcon icon={faFacebookSquare}/></a>
                        <a href="https://www.instagram.com/myseniorsofficial/" target="_blank" noreferrer noopener><FontAwesomeIcon icon={faInstagram}/></a>
                        {/* <FontAwesomeIcon icon={faLinkedinIn}/>  */}
                        <a href="https://www.youtube.com/channel/UCaU4330djD3olvI3yhUg-yg" target="_blank" noreferrer noopener><FontAwesomeIcon icon={faYoutube}/></a>
                    </div>                 
                </div>
            </div>
            <Para parastring={<>&copy; Kinships 2022 | Privacy policy</>} classes="text-center whiteme josefin copyright"/>
        </div>
    )
}