import { ButtonMe } from "../buttons/mainbutton";
import { Para } from "../para";
import { Title } from "../title";

function FaqIn(props){
    return(
        <div className="flex wid85 column">
            <Para parastring={props.question} classes="bold nobottom"/>                
            <Para parastring={props.answer}/>
        </div>
    )
}

export default FaqIn;