import { ButtonMe } from "../buttons/mainbutton";
import { Para } from "../para";
import { Title } from "../title";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/bundle';
import { Autoplay, Pagination,Navigation,Scrollbar } from 'swiper';

function AboutUS(){
    return(
        <div className="flex wid85 aboutus row-reverse align-center space-between secme mob-column" id="us">
            <div className="left">
                <Title classes={window.innerWidth < 480 ? `align-center justify-center flex` : ``} titlestring="About Kinships"/>     
                {
                    window.innerWidth < 480 ? 
                    <div className="right">
                        <div className="whitebox">
                            <div className="videobox">
                            {/* <iframe src="https://www.youtube.com/embed/igKdgta23gg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                            <img src="./assets/img/about-kinships.jpg"/>
                            </div>
                        </div>
                    </div> : null
                } 
                <div className="specialparas">
                    <Swiper
                    modules={[Navigation, Pagination, Scrollbar, Autoplay]}
                    spaceBetween={10}
                    centeredSlides={true}
                    slidesPerView={1}                                         
                    autoplay={{                  
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    >
                        <SwiperSlide>
                            <Para parastring="How do you send your family to see a doctor when you are not available?" classes="specialin"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Para parastring="What do you do when business trips overlap with your parents' checkups?" classes="specialin"/>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Para parastring="Who do you trust to take your expectant spouse for a checkup when you're at work?" classes="specialin"/>
                        </SwiperSlide>
                    </Swiper>
                </div>
                <Para parastring={<>Kinships. This  is a holistic service for all your assisted transportation, in-hospital and homecare needs. We provide tech-enabled concierge assisted transportation to and from medical care facilities anywhere and help you with everything in between. We are the proxy family member and point of contact  at the hospital for families of patients admitted there. We are also available for homecare services when there is a need for short and long term nursing.<br/><br/>Driven by sheer motivation of making the world a better place, we intervene responsibly in crucial and sensitive times, to make it easier to navigate life as it happens, even with the odd unexpected hiccups!</>}/>                
            </div>
            {
                window.innerWidth > 480 ? 
                <div className="right">
                    <div className="whitebox">
                        <div className="videobox">
                        {/* <iframe src="https://www.youtube.com/embed/igKdgta23gg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                            <img src="./assets/img/about-kinships.jpg"/>
                        </div>
                    </div>
                </div> : null
            }
        </div>
    )
}

export default AboutUS;