// import logo from './logo.svg';
import React, { useState } from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
// import './App.css';
import Home from './pages/home';
import Header from './components/header';
import Footer from './components/footer';
import BgScroll from './utils/background';
import { ParallaxProvider } from 'react-scroll-parallax';
import { TitleMe } from './utils/doctitle';
import About from './pages/about';
import ServicesPageConcierge from './pages/concierge';
import ServicesPageMobility from './pages/mobility';
import ServicesPageHomecare from './pages/homecare';
import ContactUs from './pages/contact';

function App() {
  const [joinurl, setJoinUrl] = useState(null)    
  const [burgeroff, setBurgerOffHead] = useState(65);

  const seturl = function seturl(e){    
    setJoinUrl(e);
  }

  const changebtop = (e)=>{
    console.log(e);
    setBurgerOffHead(e)
  }

  return (
    <div className="App">  
      
      <BrowserRouter basename='/'>
        <Header burgertop={burgeroff} changebtop={changebtop}/>
        <TitleMe changeurl={(e)=>{seturl(e)}}/>
        <ParallaxProvider>
          <BgScroll/>
          <Routes>
          <Route path="/" element={<Home/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/services/mobility" element={<ServicesPageMobility/>} />
            <Route path="/services/in-hospital-support" element={<ServicesPageConcierge/>} />
            <Route path="/services/home-care" element={<ServicesPageHomecare/>} />
            <Route path="/contact" element={<ContactUs/>} />  

          </Routes>
        </ParallaxProvider>
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
