import { Fade } from "react-reveal";
import { ButtonMe } from "../buttons/mainbutton";
import { Para } from "../para";
import { Title } from "../title";

function Qualityin(props){
    return(
        <div className="qualityin flex column align-center">
            <Fade bottom><img src={`./assets/img/`+props.img}/></Fade>
            <Para parastring={props.parastring} classes="bold"/>
        </div>
    )
}

export default Qualityin;