import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import Popup from "reactjs-popup";
import BookNow from "../components/services/booknow";

function Header(props){
    const [burgeroff, setBurgerOff] = useState(65);
    const [menustate, setMenuState] = useState(false);
    const [changeprop, setChangePropType] = useState("nothomecare");

    const makeprop = (e)=>{
        console.log("makeprop",e)        
        setChangePropType(e)
               
    }
    useEffect(()=>{        
        if(burgeroff !== props.burgertop){
            props.changebtop(burgeroff);
        }
        if(menustate){
            document.getElementById("line2").style.marginTop = props.burgertop+"px";
        }else{
            document.getElementById("line2").style.marginTop = "inherit";
        }
    })

    const openMobMenu = ()=>{
        if(!menustate){
            var menu = document.getElementById("mobmenu");
            document.getElementById("mobmenu").style.left = "0";
            document.getElementById("line1").style.position = "absolute";
            document.getElementById("line2").style.position = "absolute";
            document.getElementById("line3").style.position = "absolute";
            document.getElementById("line1").style.transform = "rotateZ(45deg)";
            document.getElementById("line1").style.marginTop = "10px";            
            document.getElementById("line3").style.transform = "rotateZ(-45deg)";
            document.getElementById("line3").style.marginTop = "10px";

            document.getElementById("line2").style.marginLeft = "20px";
            document.getElementById("line2").style.marginTop = props.burgertop+"px";
            document.getElementById("line2").style.transform = "rotateZ(90deg)";
            setMenuState(true)
        }else{
            offMenuDets()
        }
    }

    const offMenu = ()=>{
        offMenuDets()
    }
    const offMenuDets = ()=>{
        document.getElementById("mobmenu").style.left = "-100%";
        document.getElementById("line1").style.position = "relative";
        document.getElementById("line2").style.position = "relative";
        document.getElementById("line3").style.position = "relative";
        document.getElementById("line1").style.transform = "rotateZ(0deg)";
        document.getElementById("line1").style.marginTop = "inherit";            
        document.getElementById("line3").style.transform = "rotateZ(0deg)";
        document.getElementById("line3").style.marginTop = "inherit";

        document.getElementById("line2").style.marginLeft = "inherit";
        document.getElementById("line2").style.marginTop = "inherit";
        document.getElementById("line2").style.transform = "rotateZ(0deg)";
        setMenuState(false)
    }
    return(
        <div className="header">
            <div className="headin">
                <Fade top><Link onClick={offMenu} to="./"><img src="./assets/img/logo.png"/></Link></Fade>
                <div className="burger" onClick={openMobMenu}>
                    <div className="line line1" id="line1"></div>
                    <div className="line line2" id="line2"></div>
                    <div className="line line3" id="line3"></div>
                </div>
                <ul className="menu" id="mobmenu">
                    <li onClick={(e)=>{setBurgerOff(e.target.offsetTop)}}><Link to="./">Home</Link></li>
                    <li onClick={(e)=>{setBurgerOff(e.target.offsetTop)}}>
                        About Us
                        <ul onClick={(e)=>{e.stopPropagation()}}>
                            <li onClick={(e)=>{e.stopPropagation()}}><HashLink  onClick={offMenu} to="./about#us">About Kinships</HashLink ></li>
                            <li onClick={(e)=>{e.stopPropagation()}}><HashLink  onClick={offMenu} to="./about#story">Our Story</HashLink ></li>
                            <li onClick={(e)=>{e.stopPropagation()}}><HashLink  onClick={offMenu} to="./about#team">Meet Our Team</HashLink ></li>
                        </ul>
                    </li>
                    <li>
                        <Popup 
                            trigger={<Link to="#">
                                Book Now
                            </Link>} 
                            position="center"
                        >
                            <BookNow from="bookpopup" type={changeprop} changetype={makeprop}/>
                        </Popup>
                    </li>
                    <li onClick={(e)=>{setBurgerOff(e.target.offsetTop)}}>Services
                        <ul onClick={(e)=>{e.stopPropagation()}}>
                            <li onClick={(e)=>{e.stopPropagation()}}><Link onClick={offMenu} to="./services/in-hospital-support">In Hospital Support Services</Link></li>
                            <li onClick={(e)=>{e.stopPropagation()}}><Link onClick={offMenu} to="./services/mobility">Assisted Mobility Services</Link></li>
                            <li onClick={(e)=>{e.stopPropagation()}}><Link onClick={offMenu} to="./services/home-care">Home Care Services</Link></li>
                        </ul>
                    </li>
                    {/* <li onClick={(e)=>{setBurgerOff(e.target.offsetTop)}}>Shop</li> */}
                    <li onClick={(e)=>{setBurgerOff(e.target.offsetTop)}}><a href="https://blog.kinships.co" target="_blank">Blog</a></li>
                    {/* <li onClick={(e)=>{setBurgerOff(e.target.offsetTop)}}>Careers</li> */}
                    <li onClick={(e)=>{setBurgerOff(e.target.offsetTop)}}><Link onClick={offMenu} to="./contact">Contact</Link></li>
                </ul>
            </div>
        </div>
    )
}

export default Header;