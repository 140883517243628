import { ButtonMe } from "../buttons/mainbutton";
import { Para } from "../para";
import { Title } from "../title";
import Briefright from "./briefright";
import { useEffect, useState } from 'react';

function Brief(){
    const [changeprop, setChangePropType] = useState("nothomecare");

    const makeprop = (e)=>{
        console.log("makeprop",e)        
        setChangePropType(e)
               
    }
    return(
        <div className="flex wid85 brief align-center space-between secme mob-column-reverse">
            <div className="left mob100">
                <Title classes="mob-align-center mob-justify-center" titlestring="Why us"/>    
                {window.innerWidth < 480 ? <Briefright/> : null}
                <Para parastring={<>In India, travelling alone to the hospital for a consultation or a procedure is not an option for most patients. We wait for the availability of a family member, friend or neighbour to accompany us to the hospital. We are therefore bound by their availability and inclination to be able to visit a medical facility. At Kinships, we would like to change this to set the patient free to travel to hospitals, clinics, diagnostic centres or even the family doctor with our team of caring and warm concierges. No one ever has to be dependent on anyone again.<br/><br/>Also, public transportation in many Indian cities may not always be reliable, readily available or even safe. At Kinships we make sure our sedans are always spotlessly clean  and available when required. Technology enablers used with our vehicles make the rides safer, more convenient and very reliable. Our drivers and concierges are trained to take care of you from the time you leave your home till you are back.</>}/>                
            </div>
            {window.innerWidth > 480 ? <Briefright/> : null}
        </div>
    )
}

export default Brief;