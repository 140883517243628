import { ButtonMe } from "../buttons/mainbutton";
import { Para } from "../para";
import { Title } from "../title";
import FaqIn from "./faqin";

function Faq(props){
    return(
        <div className="wid100 faqs lightbluebg flex secme column align-center justify-center">
            <Title titlestring="FAQs" classes="justify-center align-center"/>
            {
                (props.faqs).map(element=>{
                    return <FaqIn question={element.question} answer={element.answer}/>
                })
            }
        </div>
    )
}

export default Faq;