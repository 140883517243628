import { useEffect, useState } from 'react';
import { Parallax } from 'react-scroll-parallax';

export default function BgElement(){

    const [justchange, setJustChange] = useState(true);
    useEffect(()=>{
        if(justchange){
            setJustChange(false)
        }
    })

    var getrand = ()=>{
        var random = Math.ceil(Math.random() * 10) * (Math.round(Math.random()) ? 1 : -1)        
        return random
    }

    var getimg = ()=>{
        return Math.ceil(Math.random() * 11)
    }

    var getmarginleft = ()=>{
        return Math.random() * 90
    }

    var getmargintop = ()=>{
        
        return Math.random() * document.body.clientHeight;  
    }

    var getimgfun = getimg();

    return(
        <>
            <Parallax speed={getrand()} style={{position:"absolute",marginLeft:getmarginleft()+`%`,marginTop:getmargintop()+`px`}}>
                <img src={`./assets/img/element (`+getimgfun+`).png`} style={{opacity:"0.5"}}/>
            </Parallax>
        </>
    )
}