import {base_host} from '../utils/constants';
import React, { useState, useEffect } from 'react';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import axios from 'axios';
import KinsNeighbor from '../components/kinsneighbor';
import Faq from '../components/services/faq';
import SerInfo from '../components/services/infodiv';
import BookNow from '../components/services/booknow';

function ServicesPageConcierge(props){
    return(        
        <>
            <SerInfo
                serimg={ window.innerWidth > 480 ? "conciergebanner.jpg" : "mob-concierge.jpg"}
                name="In Hospital Support Services"
                quality={[
                    {
                        quality:"In-patient support",
                        image:"inpatient.png"
                    },
                    {
                        quality:"Logistic services",
                        image:"logistics.png"
                    },
                    {
                        quality:"Report collection",
                        image:"report-collection.png"
                    },
                ]}
                infopara="We take you to the hospital assisted and in safety and walk you through every administrative process in it so you do not have to work around it on your own. We hope you stay healthy but in case you do need to get admitted in a hospital, we will stay with you as  your extended family and ensure care at the hospital and logistical support from home during your stay at the hospital. We don’t just stop at that. Whatever the requirements may be at the hospital or at home, we are happy to be of service to you. Help us help you save time and effort by getting things done with the added benefit of protecting your family from outdoor exposure."
            />
            <Faq
                faqs={[
                    {
                        question:"How will your associates help me at the hospital?",
                        answer:"Our trained associates will stay with you throughout your consultation or diagnostic test, help you with all hospital formalities, whether it’s filling up a form or collecting reports, and update your family at every stage. The Associate can help you set up a remote participation session in consultation for your relatives back home."                        
                    },
                    {
                        question:"How can I ensure my loved ones are safe with your team?",
                        answer:"We recruit professionals from trusted sources and conduct rigorous background checks. Our team is professionally trained with necessary skills, including specialised training for comprehensive support assistance to our users. Additionally, we carefully monitor the entire service experience during deployment. In the case of assisted mobility-oriented services, users can also track their family members’ and friends’ locations at all times."
                    }
                ]}
            />
            <BookNow/>
            <KinsNeighbor/>
        </>
    )
}

export default ServicesPageConcierge;